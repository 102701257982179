<template>
  <el-dialog
    title="恭喜您！报名成功"
    :visible="visible"
    class="qr-dialog"
    @close="$emit('close')"
  >
    <div class="qrcode-wrapper">
      <img src="@/assets/img/QR.png">
    </div>
    <p class="qr-text">
      扫一扫 下载鲸准APP 手机也能观看
    </p>
    <p class="qr-text">
      直播将于<span class="qr-time">{{ startTime | filterTime }}</span>准时开启
    </p>
  </el-dialog>
</template>

<script>
import moment from 'moment';

export default {
  name: 'QrDialog',
  filters: {
    filterTime(time) {
      return moment(time).format('YYYY-MM-DD HH:mm');
    },
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    startTime: {
      type: [String, Number],
      default: '',
    },
  },
};
</script>

<style scoped lang="scss">
.qr-dialog {
  /deep/ {
    .el-dialog {
      width: 350px;
      border: 1px solid #E4E5EB;
      box-shadow: 0 8px 16px 0 rgba(0,0,0,0.10);
      border-radius: 6px;
    }
    .el-dialog__body {
      padding: 0 0 24px;
      text-align: center;
    }
    .el-dialog__header {
      border-bottom: 0;
      line-height: 70px;
      padding-left: 111px;
    }
    .el-dialog__close {
      font-size: 24px;
    }
    .el-dialog__headerbtn {
      top: 23px;
    }
  }
  .qrcode-wrapper {
    text-align: center;
    margin-bottom: 24px;
    img {
      width: 160px;
      height: 160px;
    }
  }
  .qr-text {
    margin-top: 8px;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #666B71;
    letter-spacing: 0;
    line-height: 20px;
  }
  .qr-time {
    color: #FD7915;
    padding: 0 4px;
  }
}
</style>
